
import Vue from 'vue'
import { VueGoodTable } from 'vue-good-table'

export default Vue.extend({
  name: 'JuryProjectList',

  data: () => {
    return {
      award: null as any,
      columns: [
        {
          label: 'Name',
          field: 'titel',
          sortable: false,
        },
        {
          label: 'Ident',
          field: 'ident',
          sortable: false,
        },
        {
          label: 'Ort',
          field: 'ort',
          sortable: false,
        },
        {
          label: 'Ausgewählt',
          field: 'picked',
          width: '110px',
          sortable: false,
        },
        {
          label: 'Aktion',
          field: 'action',
          width: '110px',
          sortable: false,
          tdClass: 'action-td',
        },
      ],
      fill: '#FFA600',
      groupBy: 'reg_bezirk',
      groupOptions: {
        enabled: true,
        headerPosition: 'top',
        collapsable: true,
      },
      isLoading: true,
      phase: 'phase1',
      searchOptions: {
        enabled: true,
        placeholder: 'Schnellfilter...',
      },
      tableIsExpanded: false,
      total: 0,
      viewMode: 'table',
      _groupToggleListenerAdded: false,
      expandedGroups: {}
    }
  },

  computed: {
    awards (): any[] {
      return this.$store.state.awards?.awards
    },
    baseUrl (): string {
      return this.$store.state.baseUrl
    },
    currentAward (): any {
      return this.$store.state.currentAward
    },
    currentGroup (): any {
      return this.$store.state.currentJuryConf.grouping.filter((g: any) => g.key === this.groupBy)[0]
    },
    currentJuryConf (): any {
      return this.$store.state.currentJuryConf
    },
    currentPhase (): any {
      return this.$store.state.currentJuryConf.phases.filter((p: any) => p.key === this.phase)[0]
    },
    darkMode (): any {
      return this.$store.state.darkMode;
    },
    groups (): any[] {
      const res = this.$store.state.juryGroups.map((g: any) => {
        return {
          mode: 'span',
          label: g.groupName,
          data: g,
          children: g.items
        }
      })
      return res
    },
    isSelectedKey(): any {
      return this.currentPhase.isSelectedKey?.split('.')[1]
    },
    isSelectedKey2(): any {
      return this.currentPhase.isSelectedKey2?.split('.')[1]
    },
    phaseOptions (): any {
      return this.currentJuryConf.phases.map((p: any) => {
        return { name: p.name, value: p.key }
      })
    },
    openAccordionState(): any {
      return this.$store.state.openAccordionState || new Set()
    },
  },

  methods: {
    log (p: any) {
      console.log(p)
    },

    handleRowClick(params: any) {
      console.log('handleRowClick:', params);
      console.log(this.$refs.myJuryTable)
      const table: any = this.$refs.myJuryTable
      const expandedRowKeys = table.expandedRowKeys || new Set();
      console.log('handleRowClick, store: ', expandedRowKeys);
      this.$store.commit('setOpenAccordionState', expandedRowKeys)
      // Check if this is a group header ro
    },

    action (e: Event, id: any) {
      console.log('action:');
      e.preventDefault()
      this.$store.dispatch('postJuryAction', {
        awardType: this.currentAward.awardType,
        year: this.currentAward.year,
        action: { key: this.currentPhase.actions[0].key }, // TODO: allow multiple actions?
        phase: this.phase,
        id,
      }).then(() => {
        this.filterList()
      })
    },

    getProjectLinkText (p: any) {
      return p.titel + ', ' + p.bauherr_name + ' (' + p.bauherr_ort + ')'
    },

    filterList () {
      this.isLoading = true
      this.init()
    },

    init () {
      this.isLoading = true
      this.$store.dispatch('getProjectsJury', {
        awardType: this.currentAward.awardType,
        year: this.currentAward.year,
        groupBy: this.groupBy,
        phase: this.phase,
      }).then(() => {
        this.isLoading = false
      })
    },

    rowStyleClassFn(row: any) {
      return row.mode === 'span' ? 'is-group-header' : ''
    },

    switchContext (val: any) {
      if (!val) { //  || (this.currentAward && val === this.currentAward.id)
        this.$store.commit('setCurrentAward', null)
      } else {
        this.award = parseInt(val, 10)
        let res = this.awards?.filter((aw: any) => aw.id == this.award)[0]
        if (res) {
          this.$store.commit('setCurrentAward', res)
        }
      }
      return this.filterList()
    },

    toggleExpandAll() {
      const table: any = this.$refs.myJuryTable
      if (this.tableIsExpanded) {
        table?.collapseAll()
      } else {
        table?.expandAll()
      }
      this.tableIsExpanded = !this.tableIsExpanded
    },

    toggleViewMode(mode: string) {
      this.viewMode = mode
      console.log('this.viewMode: ', this.viewMode);
    },
  },

  created () {
    console.log('created()');
    if (!this.awards || this.awards.length < 1) {
      this.$store.dispatch('getAwards')
    }
    if (this.$route.params.phase) {
      this.phase = this.$route.params.phase
    }
    if (this.$route.params.group) {
      this.groupBy = this.$route.params.group
    }
    if (this.$route.params.awardId) {
      this.switchContext(this.$route.params.awardId)
      this.award = parseInt(this.$route.params.awardId, 10)
    } else {
      this.filterList()
    }
  },

  mounted() {
    console.log('mounted()');
  },

  updated() {
    console.log('updated()');

  },

  beforeDestroy() {
    // Entferne den Event-Listener
    console.log('beforeDestroy()');
  },

  watch: {
    isLoading (to) {
      if (to == false) {
        this.$nextTick(() => {
          console.log('watch.isLoading', to);
          const table: any = this.$refs.myJuryTable
          console.log('watch.isLoading', table);
          if (table) {
            console.log('watch.isLoading', this.openAccordionState);
            if (this.openAccordionState && this.openAccordionState.size > 0) {
              table.expandedRowKeys = this.openAccordionState
              console.log('watch.isLoading RESET');
              this.$store.commit('setOpenAccordionState', new Set())
            }
          }
        })
      }
    },

    groups (to) {
      let total = 0
      to.forEach((g: any) => {
        total += g.data.count
      })
      this.total = total
    },

    groupBy (to) {
      if (to !== this.$route.params.group) {
        this.$router.replace({name: 'jury-projects', params: {
          awardId: this.award,
          phase: this.phase,
          group: this.groupBy,
        }})
        this.filterList()
      }
    },

    phase (to) {
      if (to !== this.$route.params.phase) {
        this.$router.replace({name: 'jury-projects', params: {
          awardId: this.award,
          phase: this.phase,
          group: this.groupBy,
        }})
        this.filterList()
      }
    },
  },

  components: {
    VueGoodTable,
  },
})
